.header {
  height: 80px;
}

.headerBg {
  background: #f4f9ff;
  border: 1px solid var(--blue-001);
}

.logo {
  background: #fff;
  height: 30px;
  object-fit: cover;
}
