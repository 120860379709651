.header {
  background: none;
  border-bottom: 1px solid var(--gray-200);
  cursor: pointer;
}

.icon {
  color: var(--gray-500);
}

.accordionHeaderSeperator {
  max-width: 5px !important;
}

.subtitle {
  color: var(--gray-500);
}

.toggle {
  color: var(--blue-400);
  background: none;
  border: 0;
  line-height: 24px;
  height: 24px;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.toggle:hover {
  color: var(--blue-500);
}

.tooltip {
  background-color: #374046;
  color: #ffff;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
}

.tooltip::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
