.container {
  display: inline-block;
  text-align: center;
}

.image {
  display: block;
  width: 52px;
}

.name {
  display: block;
  font-size: 14px;
  margin-top: 5px;
}

.sm .image {
  width: 32px;
}
