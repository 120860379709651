.wrap {
  display: flex;
  align-items: center;
}

.textWrap {
  margin-right: 4px;
}

.text {
  white-space: nowrap;
}

.imgWrap {
  height: 36px;
  width: 36px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.imgWrap:hover {
  box-shadow: none !important;
}

.img {
  height: 30px;
  width: 30px;
}
