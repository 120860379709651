.select-input .select-input__control {
  min-height: 48px;
}

.select-input .select-input__value-container {
  padding: 2px 16px;
}

.select-input__indicator,
.select-input__dropdown-indicator {
  padding: 10px 16px !important;
}

.select-input__menu-portal {
  z-index: 11 !important;
}

/*.select-input .select-input__indicator {*/
/*  color: black;*/
/*  padding: 13px;*/
/*} *!*/
