.subTitle {
  width: 100%;
  margin-bottom: 2px;
  color: var(--gray-600);
  font-size: 14px;
  word-break: break-all;
  word-wrap: break-word;

}

.withoutEllipsis {
  text-overflow: unset;
}
