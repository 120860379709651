.siteNameText {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #374046;
}

.crumbSeparator {
  margin: 0px 6px;
  color: #84919b;
  font-size: 24px;
}
