.avatar {
  width: 32px;
  height: 32px;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}

.actionButton {
  right: 0;
}
