.item {
  padding: 0px 7px;
}

.item:hover {
  cursor: pointer;
}

.itemSelected {
  color: #fff;
  background: var(--primary);
  border-radius: 4px;
}

.itemSelected:hover {
  color: #fff;
  text-decoration: none;
  cursor: default;
}
