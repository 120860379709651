.button {
  position: fixed;
  bottom: 20px;
  right: 45%;
  z-index: 100;
  padding: 15px;
  border-radius: 100%;
  font-size: 34px;
  transform: translateY(100px);
  transition: all 0.5s ease;
}

.hideButton {
  opacity: 0;
}

.showButton {
  opacity: 1;
  transform: translateY(0);
}
