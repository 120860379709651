.custom-checkbox-round .custom-control-input ~ .custom-control-label::before,
.custom-checkbox-round .custom-control-input:checked ~ .custom-control-label::before {
  border-radius: 8px;
}
.custom-checkbox-round.custom-control-lg {
  padding-left: 1.8rem;
}
.custom-checkbox-round.custom-control-lg .custom-control-input {
  width: 1.2rem;
  height: 1.35rem;
}
.custom-checkbox-round.custom-control-lg .custom-control-label::before,
.custom-checkbox-round.custom-control-lg .custom-control-label::after {
  top: 0.2rem;
  left: -1.8rem;
}
.custom-checkbox-round.custom-control-lg .custom-control-input ~ .custom-control-label::before {
  border: var(--primary-light-gray) solid 2px;
}
.custom-checkbox-round.custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border: var(--primary) solid 2px;
}
.custom-checkbox-round.custom-control-lg .custom-control-input ~ .custom-control-label::before,
.custom-checkbox-round.custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::before,
.custom-checkbox-round.custom-control-lg .custom-control-input ~ .custom-control-label::after,
.custom-checkbox-round.custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.custom-checkbox-round.custom-control-lg .custom-control-input ~ .custom-control-label::after {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 22px;
  line-height: 21px;
  content: 'check';
  color: var(--primary-light-gray);
  text-transform: none;
  text-align: center;
  font-variation-settings: 'FILL' 1;
}
.custom-checkbox-round.custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none;
  color: #fff;
}
.custom-checkbox-round.custom-control-lg
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  content: '';
}

.label-on-left.custom-control {
  padding-left: 0;
  padding-right: 1.5rem;
}
.label-on-left .custom-control-label::after,
.label-on-left .custom-control-label::before {
  left: unset;
  right: -1.5rem;
}
.label-on-left.custom-switch.custom-control {
  padding-right: 2.25rem;
}
.label-on-left.custom-switch .custom-control-label::after,
.label-on-left.custom-switch .custom-control-label::before {
  left: unset;
  right: -2.25rem;
}
.label-on-left.custom-switch .custom-control-label::after {
  right: calc(-1.5rem + 2px);
}

.icon-unchecked {
  border: 1.8px solid var(--primary-light-gray);
  color: var(--primary-light-gray);
}
