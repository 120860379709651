.form-label {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 14px;
}

/**
 * Embeded Form Controls
 */

.embed-label-control {
  color: var(--main);
  transition: all 0.15s ease-in-out;
  outline: none;
  padding-top: 16px;
}

.form-control-lg.embed-label-control {
  padding-top: 24px;
}

.form-group-embedded .form-label {
  position: absolute;
  top: 14px;
  left: 0.75rem;
  z-index: 10;

  font-size: 14px;

  user-select: none;
  pointer-events: none;
  transition: all 250ms ease-in-out;
  animation-name: pullDown, textTransform;
}

.has-icon-left.form-group-embedded .form-label {
  left: 32px;
}

.form-group-lg.form-group-embedded .form-label {
  top: 21px;
  left: 1rem;
}

.form-group-lg.has-icon-left.form-group-embedded .form-label {
  left: 48px;
}

.form-group-embedded.focused .form-label {
  top: 4px;
  color: var(--primary-light-gray);
  font-size: 12px;
}

.form-group-lg.form-group-embedded.focused .form-label {
  top: 11px;
}

.form-group-embedded.is-invalid .form-label {
  color: var(--danger);
}

/**
 * Click To Edit
 */
.inline-edit .form-control {
  border: 1px dashed transparent;
}

.inline-edit .form-control:hover {
  border: 1px dashed var(--gray-400);
}

.inline-edit .form-control:hover {
  border: 1px dashed var(--gray-400);
}

.inline-edit .form-control:focus {
  border: 1px solid var(--blue-400);
}

/**
 * Icons
 */
.has-icon-left .form-control {
  padding-left: 32px;
}

.has-icon-right .form-control {
  padding-right: 32px;
}

.input-icon,
.input-icon .material-icons {
  font-size: 18px;
  color: var(--primary-light-gray);
}

.input-icon-left,
.input-icon-right {
  position: absolute;
  top: 50%;
  z-index: 10;
  margin-top: -8px;
}

.input-icon-left {
  left: 10px;
}
.input-icon-right {
  right: 10px;
}

.input-icon-active,
.input-icon-active .material-icons {
  color: var(--primary);
}

.input-icon-error,
.input-icon-error .material-icons {
  color: var(--danger);
}

.form-group-lg.has-icon-left .form-control {
  padding-left: 48px;
}

.form-group-lg.has-icon-right .form-control {
  padding-right: 48px;
}

.form-group-lg .input-icon,
.form-group-lg .input-icon .material-icons {
  font-size: 24px;
}

.form-group-lg .input-icon-left,
.form-group-lg .input-icon-right {
  margin-top: -10px;
}

.form-group-lg .input-icon-left {
  left: 12px;
}
.form-group-lg .input-icon-right {
  right: 12px;
}

.form-group-sm .input-icon-left,
.form-group-sm .input-icon-right {
  margin-top: -10px;
}

/**
 * Icon Buttons
 */

.input-icon-btn {
  background: transparent;
  border: 0;
  height: 18px;
  padding: 0;
  outline: none;
}

.input-icon-btn:focus {
  outline: none;
}

.input-icon-btn {
  margin-top: -12px;
}

.form-group-lg .input-icon-btn {
  height: 24px;
}

/* .form-group-lg .input-icon-btn {
  margin-top: -15px;
} */

.input-icon-clear {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 18px;
  height: 24px;
  margin-top: -10px;
  overflow: hidden;
  padding: 0;
  line-height: 1;
  text-align: center;
}

.input-icon-clear:hover .material-icons {
  color: var(--primary-dark-gray);
}

.form-group-lg .input-icon-clear {
  width: 24px;
  right: 16px;
  margin-top: -10px;
}

.form-group-lg .input-icon-clear .material-icons {
  font-size: 22px;
}
