.checkbox {
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 2px solid var(--gray-500);
  color: var(--gray-500);
}

.smallCheckbox {
  height: 16px !important;
  width: 16px !important;
}

.checkbox:global(:hover) {
  color: var(--gray-600);
  border: 2px solid var(--gray-600);
}

.checkboxChecked {
  color: #fff;
  background-color: var(--blue-400);
  border: 2px solid var(--blue-400);
}

.checkboxChecked:global(:hover) {
  color: #fff;
  background-color: var(--blue-500);
  border: 2px solid var(--blue-500);
}

.checkboxDisabled {
  color: #fff;
  background-color: var(--gray-200);
  border: 2px solid var(--gray-200);
  cursor: default;
}

.checkboxDisabled:global(:hover) {
  color: #fff;
  border: 2px solid var(--gray-200);
}

.checkboxReadOnly {
  composes: checkboxDisabled;
}

.checkboxDisabledChecked {
  background-color: var(--blue-300);
  border: 2px solid var(--blue-300);
}

.checkboxDisabledChecked:global(:hover) {
  color: #fff;
  border: 2px solid var(--blue-300);
}

.checkboxReadOnlyChecked {
  composes: checkboxDisabledChecked;
}

.iconWrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 18px;
  font-weight: 600;
}

.spinner {
  color: var(--brand) !important;
}

/* hide the real input */
.input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.inputDisabled {
  cursor: default;
}
