.title {
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 600;
}

.truncated {
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .withoutEllipsis {
  text-overflow: unset;
} */
