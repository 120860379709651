.paper {
  padding: 8px;
  background-color: #fff;
  border: 1px solid var(--gray-200);
  border-radius: 4px;
}

.noPadding {
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .paper {
    padding: 23px;
    width: 100%;
  }

  .noPadding {
    padding: 0;
  }
}
