.CalendarMonth_caption {
  padding-top: 18px;
}

.CalendarDay__today {
  background-color: var(--blue-300);
}

.DayPickerNavigation_selects {
  display: flex;
  justify-content: center;
}

.DayPickerNavigation_select {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e4e7e7;
  border-radius: 3px;
  color: #374046;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: 33px;
  line-height: 0.78;
  max-width: 94px;
  padding: 6px 9px;
  vertical-align: top;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.DayPickerNavigation_select--month {
  margin-right: 6px;
  max-width: 100px;
}

.DayPickerNavigation_select--year {
  max-width: 80px;
}
