.uppercase {
  text-transform: uppercase;
}

.badgeOver {
  border-radius: 0;
  font-size: 10px;
  left: -1px;
  letter-spacing: 0.25em;
  line-height: 10px;
  padding: 5px;
  position: absolute;
  top: 7px;
}

.danger-light {
  background-color: var(--danger-light);
  color: var(--danger);
}

.success-light {
  background-color: var(--success-light);
  color: var(--success);
}

.lime {
  background-color: var(--lime);
  color: #fff;
}
