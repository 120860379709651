.title {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.5;
  -webkit-box-orient: vertical;
  word-break: break-word;
  white-space: normal;
}

.inline {
  display: inline !important;
}
