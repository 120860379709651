.completedSection {
  padding: 5px 11px 5px 16px;
  /* width: 297px; */
  height: 22px;
  background: #37aca4 !important;
}

.completedText {
  font-weight: 800;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.card {
  box-shadow: -3px 0px 0px #fdead2;
  border-radius: 2px !important;
}

.pointer {
  cursor: pointer;
}

.cardAsset {
  box-shadow: -3px 0px 0px #829dce;
}

.optimizationCard {
  box-shadow: -3px 0px 0px #fdead2;
}

.completedCard {
  box-shadow: -3px 0px 0px #c4e2d4;
}

.completedCard > div {
  background: #f5faf7;
}

.projectName {
  font-weight: 500;
  font-size: 12px;
  color: #829dce;
}

.optimizationName {
  font-weight: 600;
  font-size: 14px;
  color: #21272c;
  word-break: break-word;
}

.assetType {
  font-weight: 700;
  font-size: 12px;
  color: #4c74b9;
}
