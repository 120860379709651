.NotFoundPage-link,
.NotFoundPage-link:hover {
  text-decoration: none;
}

.NotFoundPage-link-icon {
  font-size: 25px;
  color: var(--secondary);
}

.NotFoundPage-link-label {
  font-size: 20px;
}
