.actionBar {
  margin-top: -14px;
  margin-left: -14px;
  margin-right: -14px;
  padding: 16px 24px;
  border-bottom: 1px solid var(--gray-200);
  margin-bottom: 40px;
}
.backBtn {
  color: var(--gray-600);
  border: 0;
  margin-left: -16px;
}

.backBtn:hover {
  color: var(--danger);
}

.backBtn :global(.material-icons) {
  padding-right: 0;
}
