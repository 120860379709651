.tabItem {
  margin-bottom: -1px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--gray-300);
  color: var(--blue-300);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  transition: 0.3s ease-out;
}

.tabItem:hover {
  color: var(--blue-400);
}

.activeTabItem {
  border-top: 1px solid var(--gray-300);
  border-bottom: 1px solid var(--main-content-bg);
  background-color: var(--main-content-bg);
  color: var(--blue-400);
  transition: none;
}

.navlink {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px;
  color: inherit;
}

.navlink:hover {
  text-decoration: none;
  color: inherit;
}

.iconWrap {
  display: inline-block;
}

.tabName {
  display: none;
}

@media only screen and (min-width: 600px) {
  .navlink {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .navlink {
    padding-left: 10px;
    padding-right: 10px;
  }

  .iconWrap {
    margin-right: 10px;
  }

  .tabName {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
  }
}

@media only screen and (min-width: 1366px) {
  .navlink {
    padding-left: 40px;
    padding-right: 40px;
    text-transform: uppercase;
  }
}
