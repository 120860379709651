.dialog {
  max-width: 750px !important;
}

.dialogXL {
  max-width: 1100px !important;
}

.header {
  padding: 10px !important;
  background-color: var(--color-polar);
  border: none !important;
}

.title {
  font-size: 20px !important;
  font-weight: 400 !important;
  background-color: var(--color-polar);
}

.body {
  padding: 10px !important;
}

.footer {
  padding: 10px !important;
  border: none !important;
}

.btnCancel {
  padding: 18px 30px 16px;
}

.btnConfirm {
  padding: 18px 28px 16px;
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 20px 25px !important;
  }

  .body {
    padding: 25px !important;
  }

  .footer {
    padding: 25px !important;
  }
}
