.wrapper {
  display: flex;
  align-items: center;
}

.margins {
  margin-left: 10px;
  margin-bottom: 0;
}

.icon {
  color: #fff;
  position: relative;
  top: -1px;
  left: 4px;
}

.iconSmall {
  left: 2px;
  top: -4px;
}
