.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid transparent;
}
.avatar:hover {
  border-color: white;
  cursor: pointer;
  box-shadow: 1px 2px 2px var(--gray-500);
}
