.playBookTextLogo {
  font-size: 32px;
}

@media screen and (max-width: 1022px) {
  .playBookTextLogo {
    font-size: 21px;
  }

  .playBookSVGtLogo {
    margin-left: auto !important;
    margin-top: 2px;
  }
}
