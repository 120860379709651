.chartDesc {
  font-weight: 500;
  font-size: 20px;
  color: #869298;
}

.chart {
  overflow: hidden;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  column-gap: 5px;
}
