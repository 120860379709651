.block {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  z-index: 11;
}

.title {
  margin-right: 30px;
  font-weight: 500;
  color: var(--gray-300);
}

.description {
  padding-right: 30px;
  font-size: 15px;
}

.icon {
  position: absolute;
  top: 18px;
  right: 10px;
  color: var(--gray-300);
}

.list {
  position: relative;
}

.ul {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  list-style-type: none;
  background-color: var(--white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  z-index: 11;
}

.option {
  padding: 10px 20px;
  font-size: 15px;
  &:hover {
    background-color: var(--color-polar);
    cursor: pointer;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
