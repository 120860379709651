.tabItem {
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  color: var(--primary-dark-gray);
  transition: 0.3s ease-out;
}

.tabItem:hover {
  color: var(--main);
}

.activeTabItem {
  border-bottom: 2px solid var(--blue-400);
  color: var(--main);
}

.navLink {
  position: relative;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px;
  color: inherit;
  text-transform: uppercase;
}

.navLink:hover {
  text-decoration: none;
  color: inherit;
}

.iconWrap {
  display: inline-block;
}

.iconWrapNoText {
  margin-right: 0 !important;
}

.iconWrapActive {
  color: var(--blue-400);
}

.tabName {
  display: none;
}

@media only screen and (min-width: 600px) {
  .navLink {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (min-width: 1366px) {
  .tabItem {
    margin-right: 30px;
  }

  .tabItem:last-child {
    margin-right: 0;
  }

  .iconWrap {
    margin-right: 10px;
  }

  .navLink {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .tabName {
    display: inline-block;
    padding-right: 3px;
    font-size: 14px;
    font-weight: bold;
  }
}
