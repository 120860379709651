.alert {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
  min-width: 200px;
  max-width: 300px;
  padding: 12px 20px;
  border: 1px solid #c6c8ca;
  border-radius: 4px;
  background-color: #d6d8d9;
  color: main;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  z-index: 1060;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col1 {
  min-width: 0;
}

.message {
  margin-bottom: 0 !important;
  margin-right: 8px;
  word-wrap: break-word;
  word-break: break-all;
  display: inline-block;
}

.button {
  padding: 0 !important;
  color: var(--blue-400) !important;
}

.button:hover {
  background-color: transparent !important;
  opacity: 0.65 !important;
}

.icon {
  display: inline-block;
  padding-right: 5px;
}

.error {
  top: 0;
  bottom: auto;
  width: 33.33%;
  max-width: 100%;
  position: absolute;
  left: 16.665%;
  text-align: center;
  background-color: var(--danger);
  color: #fff;
  border: none;
  border-radius: 0;
}

.error .wrap,
.error .col1 {
  margin: 0 auto;
}

.error .message,
.error .icon {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
}
