* {
  outline: none;
}

body {
  color: var(--primary-dark-gray);
  overflow-x: hidden;
  /* font-family: 'Roboto', sans-serif; */
}

body .ql-editor {
  font-family: 'Inter', sans-serif;
}

button {
  outline: none !important;
}

.mention-yellow {
  background-color: var(--color-dandelion) !important;
  color: var(--gray-700);
}

@page {
  size: auto;
}
