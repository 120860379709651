.entity-cell .more-button {
  appearance: none;
  border: none;
  color: var(--secondary);
  margin-right: 8px;
  background-color: transparent;
}
.entity-cell .options-dropdown {
  z-index: 1;
}

.entity-cell .view-button {
  height: 24px;
  font-size: 12px;
  border-color: var(--gray-100);
  color: var(--gray-03);
}

.table tbody tr:hover .entity-cell .view-button {
  color: var(--gray-900);
  background-color: var(--white);
  border-color: var(--blue-200);
}

.table tbody tr:hover .entity-cell .view-button .view-button-icon {
  color: var(--primary);
}

.table tbody tr .entity-cell .view-button:hover {
  box-shadow: rgb(76, 116, 185, 0.5) 0px 0px 10px;
}

.table.table-selectable .sticky .entity-cell a {
  display: block;
}
