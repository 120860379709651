.filterButton {
  border: 1px solid var(--blue-vivid-300);
}

.separator {
  border-left: 1px solid var(--blue-300);
}

.filterPanel {
  max-width: 476px;
}
