.medium {
  height: 24px;
  width: 24px;
  font-size: 24px;
}
.extraSmall {
  height: 12px;
  width: 12px;
  font-size: 12px;
}
