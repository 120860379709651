.headerSubtitle {
  font-weight: 700;
  font-size: 20px;
  color: #374046;
}

.configText {
  color: #869298;
  font-size: 13px;
  margin-left: 5px;
}

.lockIcon {
  color: #f87476;
}

.companyName {
  color: #0760c3;
  font-weight: 700;
  font-size: 20px;
}
