.groupContainer {
  flex-wrap: nowrap;
}

.inputContainer {
  flex: 1 0 0;
}

.inputContainer label,
.select label {
  font-weight: bold;
}

.input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select > div:nth-child(1) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex-wrap: nowrap;
  font-size: 1rem;
}

.indicatorSeparatorStyle {
  border: none;
}

.bottomGutter {
  margin-bottom: 18px;
}

/*@media (min-width: 1500px) {*/
/*  .selectWrap {*/
/*    min-width: 50%;*/
/*  }*/

  .select {
    width: 100%;
  }
/*}*/

/*@media (min-width: 1920px) {*/
  .selectWrap {
    min-width: 35%;
  }
/*}*/
