.popover {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  border: 0;
}
.popover :global(.popover-body) {
  background: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 300;
  padding: 1rem;
  color: var(--gray-700);
}
.darkMode :global(.popover-body) {
  padding: 0.5rem;
  background: var(--gray-700);
  color: white;
}

.darkMode :global(.arrow::after) {
  border-top-color: var(--gray-700);
}
