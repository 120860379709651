.imports-wrapper {
  position: fixed;
  bottom: 0;
  right: 20px;
  height: 278px;
  width: 350px;
  z-index: 1;
  background-color: var(--white);
  box-shadow: 0px 15px 35px rgba(132, 145, 155, 0.45);
  border-radius: 12px 12px 0px 0px;
  transform: translate(0, 0);
  animation: transform 200ms cubic-bezier(0.55, 0.09, 0.68, 0.53);
  margin-right: 32px;
}

.imports-wrapper.minimized {
  transform: translate(0, 223px);
}

.imports-header {
  padding: 13px;
  border-bottom: 1px solid var(--gray-100);
  display: flex;
  align-items: center;
}

.import-icon {
  color: var(--grey-03);
}

.import-items {
  padding: 0 9px;
  overflow: auto;
  max-height: 223px;
}
