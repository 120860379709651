.activitiesRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

@media screen and (max-width: 1440px) {
  .activitiesRow {
    grid-template-columns: 1fr 1fr;
  }
}
