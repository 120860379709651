.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 124px;
  padding-left: 20px;
}

.add,
.remove {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}
