.component {
  display: inline-block;
}

.container {
  display: inline-block;
  position: relative;
}

.icon {
  vertical-align: top;
  font-size: 24px;
  cursor: pointer;
}

.readOnly .icon {
  cursor: default;
}
