.goalName {
  font-weight: 700;
  font-size: 16px;
  color: var(--primary-dark-gray);
}

.squareIcon {
  width: 14px;
}

.progressBarLabel {
  font-weight: 500;
  font-size: 12px;
  color: var(--gray-550);
}
