.h1 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
}

.h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.h3 {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0;
}

.h4 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.h5 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.h6 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.bold.bold {
  font-weight: bold;
}

.jumboTitle1 {
  font-weight: 900;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.005em;
}

.subtitle1 {
  font-size: 13px;
  margin-bottom: 0;
}

.subtitle2 {
  font-weight: normal;
  line-height: 29px;
  color: var(--primary-dark-gray);
}

.subtitle3 {
  font-weight: normal;
  font-size: 12px;
  color: var(--primary-dark-gray);
}

.body1 {
  font-size: 14px;
  line-height: 1.45;
  margin-bottom: 14px;
}

.body1:last-child {
  margin-bottom: 0;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.gutterTop {
  margin-top: 18px;
}

.gutterBottom {
  margin-bottom: 18px;
}
