.contentContainer {
  padding: 8px;
}

@media only screen and (min-width: 768px) {
  .contentContainer {
    padding: 25px;
  }
}

@media only screen and (min-width: 1000px) {
  .contentContainer {
    padding-top: 40px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 38px;
  }
}
