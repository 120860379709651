.label {
    display: block;
    color: #84919B;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

.content {
    color: #374046;
}