.radioWrap {
  position: relative;
  height: 24px;
  width: 24px;
}

.radioIconWrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.icon {
  color: var(--blue-400);
}

.iconDisabled {
  color: var(--main-content-bg);
}

.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
