.wrap {
  display: flex;
  align-items: center;
}

.col1 {
  width: 24px;
}

.col2 {
  margin-left: 10px;
}

.bold {
  font-weight: 700;
}

.label {
  margin-bottom: 0;
  cursor: pointer;
}

.labelActive {
  color: var(--blue-400);
}

.bottomGutter {
  margin-bottom: 18px;
}
