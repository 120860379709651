.link:hover,
.link:focus,
.link:active,
.link:visited {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}
