.indicator:before {
  background-color: inherit;
  position: absolute;
  top: 3px;
  left: 0px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.sm {
  font-size: 0.8rem;
}

.sm .indicator:before {
  width: 8px;
  height: 8px;
  top: 4px;
}

.withChildren {
  padding-left: 20px;
}

.sm.withChildren {
  padding-left: 15px;
}