.item {
  margin-right: 25px;
}

.item:last-child {
  margin-right: 0;
}

.bottomGutter {
  margin-bottom: 18px;
}
