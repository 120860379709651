.inputLabel {
  display: inline-block;
  margin-bottom: 9px;
  font-size: 14px;
}

.extraInfo {
  font-weight: 400;
  color: var(--primary-dark-gray);
}

.embedLabel {
  display: inline-block;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}
