.messageIcon {
  color: #ffffff;
  font-size: 30px;
  padding-top: 10px;
}

.messageText {
  top: 13px;
  font-style: normal;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-left: 20px;
}

.hideCol {
  display: none;
}
