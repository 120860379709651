.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.linkItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--main);
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
}

.linkItem:hover {
  border-color: var(--blue-vivid-100);
}

.gutterBottom {
  margin-bottom: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.rightSpace {
  padding-right: 25px;
}

.rightSpace:last-child {
  padding-right: 0;
}

.hideFirstBullet:first-child {
  padding-top: 2px;
  list-style-type: none;
}

@media screen and (max-width: 767px) {
  .hideFirstBullet:first-child {
    list-style-type: disc;
  }
}

.linkItem:last-child {
  margin-bottom: 0;
}

.iconColor {
  color: var(--primary-dark-gray);
}
