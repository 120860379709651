.modalDialog {
  width: 704px;
}

.modalContent {
  background: #ffffff !important;
  border: 1px solid #d2dcee !important;
  box-sizing: border-box !important;
  margin-bottom: 20px !important;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.14) !important;
  border-radius: 8px !important;
}

.modalContainer {
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
}

.closeButton {
  color: #84919b;
  cursor: pointer;
  font-size: 24px;
  width: 24px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.012em;
  color: #182128;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.008em;
  color: #869298;
}

.addonSection {
  width: 632px;
  background: #f9fbff;
  border-radius: 8px;
}

.addonText {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.008em;
  color: #21272c;
}

.addonQuantityText {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 9px;
  letter-spacing: -0.008em;
  color: #374046;
}

.addonPrice {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.008em;
  color: #182128;
}

.addonRecurring {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 9px;
  letter-spacing: -0.008em;
  color: #869298;
}

.addonIcon {
  width: 31px;
  height: 31px;
  font-size: 31px;
  color: #829dce;
}

.addonIncrement {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 9px;
  letter-spacing: -0.008em;
  color: #869298;
}

.currentPlanButton {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.008em;
  color: #207de9;
  margin: 0px 4px;
  max-width: 131px;
}

.tosInfoButton {
  text-decoration: underline;
  cursor: pointer;
}

.tosDescription {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #374046;
}

.addonDisclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.008em;
  color: #374046;
  max-width: 279px;
}

.tosCheckbox {
  min-width: 20px;
}

.tosLabel {
  max-width: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #374046;
  margin: 0px 8px;
}

.cancelButton {
  padding: 12px 24px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #207de9;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 14px;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.008em;
  color: #207de9;
}

.submitButton {
  padding: 12px 24px;
  height: 48px;
  background: #207de9;
  border-radius: 4px;
  margin: 0px 14px;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.008em;
  color: #ffffff;
}
