.cardEditLink {
  display: block;
  text-transform: uppercase;
  color: var(--blue-400);
  font-size: 14px;
  font-weight: 600;
}

.cardEditLink:hover,
.cardEditLink:focus {
  color: var(--blue-500);
  text-decoration: none;
}
