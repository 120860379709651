.title {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 44px;
  letter-spacing: -0.012em;
  color: #0b1720;
}

.subTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.008em;
  color: #869298;
}

.accordionSpacer {
  margin-bottom: 10px;
}

.thumbnailLink {
  cursor: pointer;
  text-decoration: none;
}

.headContainer {
  margin-bottom: 25px;
}

.accordionHeaderIcon {
  color: #a6b9de !important;
}
