.ErrorPage-info h1 {
  font-size: 6.5rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 0px;
}
.ErrorPage-info h2 {
  font-size: 2rem;
}

.ErrorPage * {
  font-size: 20px;
}
