.Pagination {
  border: 1px solid #dce1e4;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.Pagination ul {
  margin: 0;
  padding: 0;
}
.Pagination__item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
.Pagination__item .material-icons {
  display: block;
}
.Pagination a,
.Pagination .Pagination__item__archor {
  background-color: transparent;
  color: #84919b;
  cursor: pointer;
  display: block;
  min-height: 40px;
  outline: 0 none;
  padding: 12px 10px 14px;
  text-align: center;
}
.Pagination__item--active .Pagination__item__archor {
  color: #4c74b9;
  font-weight: bold;
  pointer-events: none;
}
.Pagination__item--break a {
  color: #84919b;
  pointer-events: none;
}
.Pagination .Pagination__item__archor {
  cursor: pointer;
}
.Pagination__item--no-display a {
  color: #e6e9eb;
  pointer-events: none;
}
.Pagination__item:first-child a,
.Pagination__item:first-child .Pagination__item__archor {
  padding: 12px 16px 14px;
}
.Pagination__item:last-child a,
.Pagination__item:last-child .Pagination__item__archor {
  padding: 12px 16px 14px;
}

.Pagination--border {
  border: 0;
}
.Pagination--border a,
.Pagination--border .Pagination__item__archor {
  border: 1px solid #dce1e4;
  border-right: 0;
  min-width: 47px;
  padding: 12px 19px 12px 18px;
}
.Pagination--border .Pagination__item--active .Pagination__item__archor {
  border: 1px solid #4c74b9;
  color: #4c74b9;
  font-weight: regular;
  padding: 12px 18px 12px 18px;
}
.Pagination--border .Pagination__item:first-child a,
.Pagination--border .Pagination__item:first-child .Pagination__item__archor {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 12px 18px 12px 18px;
}
.Pagination--border .Pagination__item:last-child a,
.Pagination--border .Pagination__item:last-child .Pagination__item__archor {
  border-right: 1px solid #dce1e4;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 12px 18px 12px 18px;
}
