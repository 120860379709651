/* Using http://chir.ag/projects/name-that-color to get color name */
:root {
  --brand: #4c74b9;
  --success-darken: #1e8d57;
  --warning-darken: #d57707;
  --info-darken: #741f72;
  --danger-darken: #e91317;
  --secondary-darken: #80919c;
  --hover-yellow: #fdf5d0;
  --link-hover: #0056b3;
  --color-polar: #f3f8fc;
  --color-foam: #edfef6;
  --color-alabaster: #fcfcfc;
  --color-jungle-green: #27af6d;
  --color-steel-blue: #4d73ba;
  --color-hit-gray: #abb4bb;
  --color-limed-spruce: #384148;
  --color-lima: #39d62b;
  --color-early-dawn: #fffae5;
  --color-dandelion: #ffdd62;
  --color-cornflower-blue: #f2f8fc;
  --color-perano: #a8c3f3;
  --fountain-blue: #4dc1b9;
  --light: #f2f2f2;
  --lightning-yellow: #ffc723;
  --brandy-punch: #d6722b;
}
/* 
:root {
  --color-polar: #f3f8fc;
  --color-foam: #edfef6;
  --color-alabaster: #fcfcfc;
  --color-jungle-green: #27af6d;
  --color-steel-blue: #4d73ba;
  --color-hit-gray: #abb4bb;
  --color-limed-spruce: #384148;
  --color-lima: #39d62b;
  --color-early-dawn: #fffae5;
  --color-dandelion: #ffdd62;
  --color-cornflower-blue: #f2f8fc;
  --color-perano: #a8c3f3;
  --brand: #4c74b9;
  --blue-500: #3a5c96;
  --success: #26b16d;
  --success-darken: #1e8d57;
  --warning: #f7941d;
  --warning-darken: #d57707;
  --info: #92278f;
  --info-darken: #741f72;
  --danger: #f04d50;
  --danger-darken: #e91317;
  --main: #374046;
  --light-grey: #a9b4bc;
  --main-darken: #2c3338;
  --light: #fff;
  --background: #f1f3f6;
  --border: #dce1e4;
  --error: #dc3545;
  --secondary: #a9b4bc;
  --secondary-darken: #80919c;
  --placeholder: #aeb0b2;
  --hover-yellow: #fdf5d0;
  --pattens-blue: #deebff;
  --fountain-blue: #4dc1b9;
  --celery: #99cc63;
  --link-hover: #0056b3;
  --link: #007bff;
  --sunglow: #ffd12d;
  --lightning-yellow: #ffc723;
  --brandy-punch: #d6722b;
  --cornflower-blue: #f8f9fa;
  --disabled-unchecked-checkbox: #eaeaea;
} */
