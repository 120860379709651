.emailAddress {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.emailAddress:hover {
  text-decoration: none;
}
