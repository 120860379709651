.contentWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.contentWrapBlock {
  justify-content: space-between;
}

.icon {
  padding-right: 8px;
}

.icon:only-child {
  padding-right: 0;
}

.text {
  display: inline-block;
}

.text:only-child {
  padding-left: 0;
}

.filler {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.disabled {
  cursor: default;
  opacity: 0.65;
}

.round {
  border-radius: 50%;
}

.btnXs {
  padding: 2px 4px;
  line-height: 1;
}

.btnXs.round {
  padding: 2px;
}

.btnSm {
  padding: 4px 8px;
  line-height: 1.2;
}

.btnSm.round {
  padding: 4px;
}

.btnMd {
  padding: 8px 17px;
  line-height: 1.6;
}

.btnMd.round {
  padding: 8px;
}

.btnLg {
  padding: 18px 17px 16px;
  line-height: 1.75;
}

.btnLg.round {
  padding: 18px;
}

.borderless {
  border: 1px solid transparent !important;
}

.uppercase {
  text-transform: uppercase;
}

.btnGroup {
  border-radius: 0;
}

.btnGroup:not(:last-child) {
  border-right: 0;
}

.btnGroup:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btnGroup:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.jumbo {
  font-size: 18px;
  line-height: 29px;
  min-height: 54px;
}

.extraPadding {
  padding: 20px;
}

.normalWeight {
  font-weight: normal;
}
