.timeline {
  position: relative;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  width: 2px;
  background: repeating-linear-gradient(
    0,
    transparent,
    transparent 14px,
    #84919b 14px,
    #84919b 24px
  );
  content: '';
}

.timeline-event {
  position: relative;
  margin-left: 46px;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: #e7eaef;
  color: #374046;
}
.timeline-event:before {
  position: absolute;
  top: 10px;
  left: -8px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 0;
  border-color: transparent #e7eaef transparent transparent;
}

.timeline-event-session {
  margin-left: 0;
  padding: 4px 8px;
  background-color: var(--cyan);
  overflow: hidden;
}

.session-title {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.timeline-event-date {
  color: #84919b;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0;
}

.timeline-event-title {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 4px;
}

.timeline-event-url {
  font-weight: 400;
  font-size: 14px;
  line-break: loose;
  word-break: break-all;
}

.timeline-event-link {
  font-size: 14px;
}

.timeline-event-source {
  background-color: rgba(0, 0, 45, 0.04);
}

.timeline-event-icon {
  position: absolute;
  top: 0;
  left: -46px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 1px 3px #dfe1e5;
  color: var(--primary);
}
.timeline-event-icon .material-icons {
  font-size: 20px;
}

.timeline-event-lead .timeline-event-icon,
.timeline-event-event .timeline-event-icon,
.timeline-event-chat .timeline-event-icon {
  box-shadow: none;
  background-color: var(--orange);
  color: #fff;
}

.timeline-event-company .timeline-event-icon {
  box-shadow: none;
  background-color: var(--primary);
  color: #fff;
}
