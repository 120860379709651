.indicatorSeparatorStyle {
  border: none;
}

.bottomGutter {
  margin-bottom: 18px;
}

.input {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

.placeholder {
  position: absolute;
  left: 10px;
  color: var(--placeholder);
  margin-top: 0;
  margin-bottom: 0;
}

.selectInputGroupStyles {
  color: var(--color-hit-gray);
  font-size: 2em;
}
