.wrap {
  align-items: center;
  background: linear-gradient(180.04deg, #1C2C48 1%, #4266A2 99.99%);
  color: #FFF;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.spinner {
  margin-right: .5rem;
  opacity: .5;
}

.text {
  align-items: center;
  display: flex;
  margin-top: 1rem;
}
