.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 15px;
  padding-left: 8px;
  padding-right: 8px;
  background: var(--gray-100);
  border-bottom: 1px solid var(--gray-300);
}

.col {
  position: relative;
  padding: 0 30px 0 6px;
}

.col::before {
  position: absolute;
  display: block;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 1px;
  height: 30px;
  background-color: #eef4ff;
}

@media only screen and (min-width: 768px) {
  .wrap {
    padding-left: 18px;
    padding-right: 18px;
  }

  .col::before {
    height: 38px;
  }
}

@media only screen and (min-width: 1000px) {
  .col:before {
    display: none;
  }
}
