.webAddress {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.webAddress:hover,
.webAddress:focus {
  text-decoration: none;
}
