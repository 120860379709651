.progressBar {
  width: 100%;
  height: 6px;
  background: #cce4ff;
  border-radius: 12px;
  position: relative;
}

.progress {
  background: #207de9;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}
