.bulk-action-core-modal {
  width: auto;
  height: auto;
  bottom: 0;
  top: auto;
  left: 40%;
  pointer-events: none;
}

.bulk-action-core-modal .modal-content {
  background-color: var(--white);
  flex-direction: row;
  margin-top: 100%;
  border: 1px solid var(--blue-001);
  box-shadow: 0px 2px 18px rgba(76, 116, 185, 0.32);
  align-items: center;
}

.selection-info {
  background-color: var(--blue-vivid-500);
  border-radius: 4px 0 0 4px;
  padding: 26px;
}

.bulk-action-core-modal .modal-dialog.modal-dialog {
  max-width: 100%;
}

.bulk-action-core-modal .bulk-action-button {
  height: 42px;
  font-size: 24px;
}

.bulk-action-core-modal .action-icon {
  font-size: 36px;
}

.force-scroll {
  overflow: scroll !important;
}
