.list {
  position: relative;
  height: 36px;
  margin: 0;
  margin-right: 20px;
  padding: 0;
  list-style: none;
}

.item {
  position: absolute;
  top: 0;
}

.avatar {
  margin: 0;
  padding: 0;
  border: 2px solid var(--gray-200);
  border-radius: 50%;
}
