.cardAvatar {
  padding-top: 1px;
  padding-right: 14px;
}

.imageWrap {
  display: block;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: var(--gray-300);
}

.smallImageWrap {
  height: 32px;
  width: 32px;
}

.image {
  display: block;
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.smallImage {
  height: 32px;
  width: 32px;
}

.number {
  text-align: center;
  line-height: 32px;
}
