.table {
  vertical-align: middle;
  border-collapse: separate; /* Don't collapse, we use this so borders stay with sticky columns*/
  border-spacing: 0;
  border: 1px solid var(--gray-300);
}

.table-fixed {
  table-layout: fixed;
}

.table.table-loading tbody {
  position: relative;
  display: table;
  width: 100%;
}
.table.table-loading thead {
  position: relative;
  display: table;
  width: 100%;
}
.table.table-loading tbody:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-image: linear-gradient(to right, transparent 50%, rgba(0, 0, 0, 0.05) 50%);
  background-size: 200% 100%;
  animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@keyframes loading {
  0% {
    background-position: 0;
  }
  50% {
    background-position: -30%;
  }
  80% {
    background-position: -100%;
  }
  100% {
    background-position: -200%;
  }
}
.table.table-transparent {
  border: none;
}
.table.table-transparent th,
.table.table-transparent .th,
.table.table-transparent tbody tr:nth-of-type(even),
.table.table-transparent tbody tr,
.table.table-transparent tbody tr:hover {
  background-color: transparent;
}
.table.table-transparent .cell-with-select {
  border-color: transparent;
  background-color: var(--blue-001);
}
.table.table-transparent tbody tr:hover .cell-with-select {
  background-color: var(--blue-001);
}
.table.table-transparent tbody tr td.sticky,
.table.table-transparent thead tr th.sticky,
.table.table-transparent tbody tr:hover td.sticky {
  background-color: var(--white);
}
.table th,
.table .th,
.table td,
.table .td {
  position: relative;
  vertical-align: middle;
  padding-left: 12px;
  padding-right: 12px;
}
.table th,
.table .th {
  background-color: var(--blue-00);
  font-weight: 500;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  border-bottom: 1px solid var(--gray-300) !important;
  padding-bottom: 12px;
  padding-top: 12px;
  border-top: none;
}
.table th.sortable {
  padding-right: 20px;
}
.table tbody tr:first-child td {
  border-top: none;
}
.table tbody tr:nth-of-type(even) {
  background-color: var(--white);
}
.table tbody tr {
  background-color: var(--table-accent-bg);
}
.table tbody tr.blurred-row td > div {
  filter: blur(8px);
  pointer-events: none;
}
.table tbody tr.blurred-row td > div .cell-with-select {
  display: none !important;
}
.table tbody tr:hover {
  background-color: var(--table-hover-bg);
}
.table .sort-indicator {
  position: absolute;
  bottom: 10px;
  right: 0;
  color: var(--gray-700);
}
.table .sort-indicator {
  opacity: 0.25;
}
.table .sortable:hover .sort-indicator,
.table .sorting-asc .sort-indicator,
.table .sorting-desc .sort-indicator {
  color: var(--blue-vivid-500);
  opacity: 1;
}
.table .sorting-desc .sort-indicator {
  transform: rotate(180deg);
  bottom: 12px;
}
.table .sticky.sticky {
  border-right: 1px solid var(--gray-300) !important;
  position: sticky !important;
  top: 0;
  left: 0;
  z-index: 1;
}
.table.table-scrollable {
  overflow: auto;
}
.table.table-scrollable.fade-scroll::after {
  content: '';
  width: 100%;
  height: calc(100% - 70px);
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(90deg, transparent 80%, white);
  pointer-events: none;
}
.table.table-scrollable .toggle-active {
  z-index: 2;
}
.table.table-selectable.table-select-active .cell-with-select,
.table.table-selectable.table-select-active-show .cell-with-select {
  width: 28px;
  padding-left: 6px;
}
.table.table-selectable.table-select-active .cell-with-select .custom-control,
.table.table-selectable.table-select-active-show .cell-with-select .custom-control {
  display: block;
}
.table.table-selectable.table-select-active .cell-with-select + div,
.table.table-selectable.table-select-active-show .cell-with-select + div {
  transition: all 0.1s linear;
  padding-left: 18px;
}
.table.table-selectable tbody tr:hover .cell-with-select {
  background-color: var(--blue-200);
}
.table.table-selectable .cell-with-select,
.table.table-selectable .cell-with-select-show {
  position: absolute;
  left: 0;
  top: 0;
  border-color: var(--white) !important;
  background-color: var(--blue-001);
  border-right: none;
  height: 100%;
  width: 10px;
  transition: all 0.1s linear;
  pointer-events: all;
}
.table.table-selectable .cell-with-select .custom-control,
.table.table-selectable .cell-with-select-show .custom-control {
  display: none;
}
.table.table-selectable .cell-with-select:hover,
.table.table-selectable .cell-with-select-show {
  width: 28px;
  padding-left: 6px;
}
.table.table-selectable .cell-with-select:hover .custom-control,
.table.table-selectable .cell-with-select-show .custom-control {
  display: block;
}
.table.table-selectable .cell-with-select:hover + div,
.table.table-selectable .cell-with-select-show + div {
  transition: all 0.1s linear;
  padding-left: 18px;
}
.table.table-selectable .cell-with-select-show {
  background-color: transparent;
  border-right: 1px solid var(--gray-300) !important;
}
.table.table-selectable .selection-column {
  padding-left: 24px;
}
.table tbody tr:nth-of-type(even) td.sticky {
  background-color: var(--white);
}
.table tbody td.sticky {
  background-color: var(--table-accent-bg);
}
.table tbody tr:hover td.sticky {
  background-color: var(--table-hover-bg);
}

.PW .PW__entries-container {
  color: #84919b;
  font-size: 14px;
  font-family: 'Inter';
}

.PW_Select {
  display: inline-block;
  max-width: 6rem;
}

.PW_Select .select-input__control {
  border-color: rgb(220, 225, 228);
  min-height: 48px !important;
  justify-content: center !important;
}

.PW_Select .select-input__value-container {
  min-width: 62px;
  max-width: 5rem;
  padding: 0 !important;
}

.PW_Select .select-input__single-value {
  font-weight: bold;
}

.PW_Select .select-input__input-container {
  padding: 0 !important;
  margin: 0 !important;
}

.PW_Select .select-input__indicator {
  color: black;
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}
