.btn:hover {
  background-color: var(--gray-400);
}

.control {
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  border-width: 0px;
  width: 130px;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  padding-bottom: 0px;
  padding-left: 30px;
}
.control:hover {
  cursor: pointer;
}
.control:focus {
  width: calc(100% - 52px);
  outline: none;
}

.icon {
  padding: 0px;
  border: 0px;
  position: absolute;
  top: 5px;
  left: 3px;
}
.icon:hover {
  cursor: pointer;
}

.label {
  line-height: 25px;
}
