.importitem-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed var(--grey-01);
  padding: 8px;
}

.importitem-progress-bar {
  height: 2px;
}
